<script>
  import { Link } from 'svelte-routing'
  import { Nav } from 'sveltestrap'
  import PageItem from './PageItem.svelte'

  export let url = '?page=%d'
  export let perPage
  export let totalPages
  export let currentPage

  let pages = []
  let nextPage = null
  let prevPage = null
  let firstPage = 1
  let lastPage = null

  const formatUrl = (url, page) => url.replace(/%d/, page)

  $: {
    const newPage = parseInt(currentPage) + 1
    nextPage = newPage <= totalPages ? newPage : null
  }
  $: {
    const newPage = parseInt(currentPage) - 1
    prevPage = newPage > 0 ? newPage : null
  }
  $: {
    const begin = currentPage - 3 > 0 ? currentPage - 3 : 0
    pages = Array.from({ length: totalPages }, (v, i) => i + 1).slice(
      begin,
      currentPage + 2
    )
  }
  $: lastPage = totalPages
  $: currentPage = parseInt(currentPage)
</script>

<Nav class={$$props.class}>
  <ul class="pagination">
    <PageItem
      to={formatUrl(url, firstPage)}
      disabled={firstPage === currentPage}
      text="First" />

    <PageItem to={formatUrl(url, prevPage)} disabled={!prevPage} text="Prev" />

    {#if pages.length > 0}
      {#each pages as page}
        {#if page === currentPage}
          <li class="page-item active">
            <span class="page-link">
              {page}
              <span class="sr-only">(current)</span>
            </span>
          </li>
        {:else}
          <li class="page-item">
            <Link to={formatUrl(url, page)}>
              <span class="page-link">{page}</span>
            </Link>
          </li>
        {/if}
      {/each}
    {/if}

    <PageItem to={formatUrl(url, nextPage)} disabled={!nextPage} text="Next" />

    <PageItem
      to={formatUrl(url, lastPage)}
      disabled={lastPage === currentPage}
      text="Last" />
  </ul>
</Nav>
