<script>
  import { Spinner } from 'sveltestrap'
  export let minHeight = '100vh'
</script>

<style>
  .center {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>

<div class="center" style="min-height: {minHeight}">
  <Spinner />
</div>
