// エラーデータの型は
// { [key: string]: string[] | { [key: string]: [string] }
// なので、一次元配列に変換する
export default data =>
  Object.values(data).reduce((acc, val) => {
    if (val instanceof Object) {
      return [...acc, ...Object.values(val).reduce((ac, v) => [...ac, v], [])]
    }
    return [...acc, val]
  }, [])
