<script>
  import { Link } from 'svelte-routing'
  export let disabled = false
  export let to
  export let text
</script>

{#if disabled}
  <li class="page-item disabled">
    <span class="page-link">{text}</span>
  </li>
{:else}
  <li class="page-item">
    <Link {to} class="page-link">
      <span class="page-link">{text}</span>
    </Link>
  </li>
{/if}
