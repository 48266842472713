import ApiClient from './ApiClient'
import camelcase from 'camelcase-keys'

const getToken = () =>
  document.querySelectorAll('meta[name="csrf-token"]')[0].content

const ssl = () => document.location.protocol === 'https:'

const defaultOptions = { ssl: ssl() }

export const createClient = (options = {}) =>
  new ApiClient({ ...defaultOptions, ...options })

export const createClientWithToken = (options = {}) =>
  createClient({ ...options, token: getToken() })

export const createErrorHandler = (handler, commonHandler) => response => {
  let errorMessage
  if (response instanceof Error) {
    errorMessage =
      'ネットワークエラーが発生しました。通信環境をご確認の上、再度お試しください。'
  } else if (response.status === 500) {
    errorMessage =
      '一時的に接続がしづらくなっております。しばらくしてから再度お試しください。'
  }

  errorMessage && typeof commonHandler === 'function'
    ? commonHandler(errorMessage, response)
    : handler(response, { errorMessage })
}

export const camelizer = response => ({
  ...response,
  data: camelcase(response.data, { deep: true })
})
